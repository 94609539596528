const Skeleton = {
  baseStyle: {
    height: '15px',
  },
  defaultProps: {
    startColor: 'gray.200',
    endColor: 'gray.400',
  },
};

export default Skeleton;
