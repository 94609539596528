import { Button as CButton, ButtonProps } from '@chakra-ui/react';
import React from 'react';

export type IButtonProps = ButtonProps;

const Button: React.FC<IButtonProps> = ({ children, ...props }) => {
  return <CButton {...props}>{children}</CButton>;
};

export default Button;
