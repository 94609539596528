import create from 'zustand';
import { combine, persist } from 'zustand/middleware';

const featureList = {
  features: {
    track_time: false,
    track_gps: false,
    track_mileage: false,
    jobs: false,
    integrations: false,
    scheduling: false,
    time_off: false,
    auto_clock_in_out: false,
    message_blast: false,
    photo_attachments: false,
    manager_admin_approvals: false,
    capture_start_end_addresses: false,
    multiple_payroll_overtime: false,
    public_api: false,
    commuter_mileage: false,
    segmented_tracking: false,
    suggested_mileage: false,
  },
};

const featureStore = combine(featureList, set => ({
  addFeatures: (newFeatures: typeof featureList.features) => set(() => ({ features: newFeatures })),
}));

export const useFeatureStore = create(persist(featureStore, { name: 'features' }));
