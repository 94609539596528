import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export function initSentry() {
  if (import.meta.env.PROD) {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
      // This is for the sourcemaps. Doc: https://docs.sentry.io/platforms/javascript/sourcemaps/troubleshooting_js/legacy-uploading-methods/
      release: import.meta.env.VITE_SENTRY_RELEASE,
      integrations: [
        new BrowserTracing({}),
        new Sentry.Integrations.GlobalHandlers({ onunhandledrejection: false, onerror: true }),
      ],
      ignoreErrors: [
        'TypeError',
        'TypeError: Object.fromEntries is not a function',
        'SecurityError: Failed to read the "localStorage" property from "Window": Access is denied for this document.',
        'Error: @math.gl/web-mercator: assertion failed.',
        "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.6,
    });
  }
}

/**
 * Catch global errors and send the caught error to Sentry.
 *
 * @param {Object} error
 * @param {Object} errorInfo
 */
export function catchErrorsWithScope(error: Error, errorInfo: any) {
  if (import.meta.env.PROD) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }
}

export function WithSentryProfiler(component: React.FunctionComponent) {
  if (import.meta.env.PROD) {
    return Sentry.withProfiler(component);
  }
  return component;
}
