// import { Flex, Text } from '@chakra-ui/react';

/**
 * Banner template for announcement
 * Remove return null statement and uncomment below commented code for displaying banner
 */
const Banner = () => {
  return null;

  // return (
  //   <Flex alignItems="center" justifyContent="center" px="3" py="2" bg="secondary.400" textAlign="center">
  //     <Text fontSize={{ base: 'sm', lg: 'md' }} color="white">
  //       On April 15th, we will be switching from new.timeero.com to app.timeero.com.
  //       <Text as="span" display={{ base: 'none', md: 'inline-block' }}>
  //         Please update your bookmarked url on April 15th.
  //       </Text>
  //     </Text>
  //   </Flex>
  // );
};

export default Banner;
