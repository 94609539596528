import { useUserStore } from 'store/useUserStore';

/**
 *
 * @param {any} access
 * @returns {boolean}
 */
export function allowedRoute(access: any) {
  const user: any = useUserStore.getState().user;

  if (!access) return true;
  if (access.roles?.includes(user?.userInfo.roleId)) return true;
  if (access?.rolesWithPermission?.length) {
    for (const { role, permissions } of access?.rolesWithPermission) {
      if (
        role === user?.userInfo.roleId &&
        permissions?.every((permission: any) => user?.permissions[`${permission as string}`])
      ) {
        return true;
      }
    }
  }

  return false;
}
