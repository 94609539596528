const colors = {
  primary: {
    50: '#F3F6F9',
    100: '#DDDEEA',
    200: '#2C75F1',
    300: '#2667C9',
    400: '#5261E9',
    500: '#4852AF',
    600: '#3D4696',
    700: '#373D74',
    800: '#333A7D',
    900: '#2A2F5A',
  },
  secondary: {
    50: '#FFF8F1',
    100: '#FEF1EA',
    200: '#FF7E7E',
    300: '#FF9534',
    400: '#F1732C',
    500: '#FF6720',
    600: '#E95414',
    700: '#FA5555',
  },
  success: {
    500: '#27AE60',
  },
  warning: {
    500: '#FFD84C',
  },
  gray: {
    100: '#F9F9FD',
    200: '#E1E2EE',
    300: '#C1CBD8',
    400: '#A9ACC2',
    500: '#656886',
    600: '#5A687A',
    900: '#1c2045',
  },

  //NEW DESIGN COLORS
  newGray: {
    100: '#FAFAFA',
    200: '#E8E8E8',
    500: '#ACACAC',
    800: '#686868',
    1000: '#111111',
  },
  newBlue: {
    100: '#F1F2F9',
    200: '#E1E2EE',
    300: '#656886',
    500: '#4852AF',
    1000: '#2A2F5A',
  },
  newOrange: {
    100: '#FFF3E6',
    1000: '#EE7038',
    5000: '#ff4d00',
  },
  newRed: {
    100: '#FEEEEE',
    1000: '#FA5656',
  },
};

export default colors;
