import { localStorageConstants } from 'global/storageConstants';
import * as localStorage from 'utils/storage';

type TTokenObj = {
  accessToken: string;
  userUniqueToken: string;
  expiryDate?: string;
};

type TReturnTypes = {
  setToken: (tokenObj: TTokenObj) => void;
  getAccessToken: () => string | null;
  getUserUniqueToken: () => string | null;
  getTokenExpiryDate: () => string | null;
  clearToken: () => void;
};

const token = (): TReturnTypes => {
  function _setToken(tokenObj: TTokenObj) {
    localStorage.set(localStorageConstants.ACCESS_TOKEN, tokenObj.accessToken);
    localStorage.set(localStorageConstants.USER_UNIQUE_TOKEN, tokenObj.userUniqueToken);
    if (tokenObj.expiryDate) {
      localStorage.set(localStorageConstants.EXPIRY_DATE, tokenObj.expiryDate);
    }
  }

  function _getAccessToken() {
    return localStorage.get(localStorageConstants.ACCESS_TOKEN) === 'undefined'
      ? undefined
      : localStorage.get(localStorageConstants.ACCESS_TOKEN);
  }

  function _getUserUniqueToken() {
    return localStorage.get(localStorageConstants.USER_UNIQUE_TOKEN) === 'undefined'
      ? undefined
      : localStorage.get(localStorageConstants.USER_UNIQUE_TOKEN);
  }

  function _getTokenExpiryDate() {
    return localStorage.get(localStorageConstants.EXPIRY_DATE) === 'undefined'
      ? undefined
      : localStorage.get(localStorageConstants.EXPIRY_DATE);
  }

  function _clearToken() {
    localStorage.remove(localStorageConstants.ACCESS_TOKEN);
    localStorage.remove(localStorageConstants.USER_UNIQUE_TOKEN);
  }

  return {
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getUserUniqueToken: _getUserUniqueToken,
    getTokenExpiryDate: _getTokenExpiryDate,
    clearToken: _clearToken,
  };
};

export default token();
