import * as React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

import TrackerProvider from 'components/TrackerProvider';
import App from 'containers/App';
import { initSentry } from 'utils/sentry';

import './i18n';
import reportWebVitals from './reportWebVitals';

initSentry();

//GOOGLE ANALYTICS
ReactGA.initialize(import.meta.env.VITE_GOOGLE_CONVERSION_ID!);

ReactDOM.render(
  <React.StrictMode>
    <TrackerProvider config={{}}>
      <App />
    </TrackerProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
