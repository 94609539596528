const Drawer = {
  sizes: {
    xs: {
      header: {
        px: 4,
        py: 3,
      },
      body: {
        px: 4,
      },
    },
  },
};

export default Drawer;
