import { format, formatRelative } from 'date-fns';

import { MILES_IN_KILOMETERS } from 'global/appConstants';
import { useAdpWfnStore } from 'store/useAdpWfnStore';
import { useFeatureStore } from 'store/useFeatureStore';
import { useIntegrationStore } from 'store/useIntegrationStore';
import { useUserStore } from 'store/useUserStore';
import tokenService from 'utils/token';

import toaster from './toaster';

/**
 * Check for empty object
 * @param {Object} value Object
 * @returns {Boolean}
 */
export function isEmptyObject(obj: { [key: string]: any }) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

/**
 * Check for empty object and possibly undefined
 * @param {Object} value Object
 * @returns {Boolean}
 */
export function isEmptyObjectWithNull(obj?: { [key: string]: any }) {
  // We need to use a juggling-check as !{property: value} will evaluate to false
  if (obj == null) return obj;
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

/**
 * Check for object with empty values
 * @param {Object} value Object
 * @returns {Boolean}
 */
export function isEmptyObjectValues(obj: { [key: string]: any }) {
  return !Object.values(obj).some(element => element);
}

/**
 *
 * @param {number} meters
 * @param {number} latitude
 * @returns {number}
 */
export function metersToPixelsAtMaxZoom(meters: any, latitude: any) {
  return meters / 0.075 / Math.cos((latitude * Math.PI) / 180);
}

/**
 *
 * @param {Function} apiCallFunction
 * @param {Object} searchParams
 * @param {Function} apiCall
 */
export async function getAsyncSelectLoadOptions(
  apiCallFunction: Function,
  { page, page_size = 25, ...searchParams }: { page: number; page_size?: number; [key: string]: any }
) {
  try {
    const response = await apiCallFunction({ page: page, page_size: page_size, ...searchParams });

    const { current_page, last_page } = response?.meta_data?.pagination;

    return {
      options: response?.payload,
      hasMore: last_page > current_page,
      additional: {
        page: page + 1,
      },
    };
  } catch (err: any) {
    toaster.error({
      description:
        err?.message?.split(' ').length > 10 ? 'An error has occured. Please try again later.' : err?.message,
    });
    return {
      options: [],
      hasMore: false,
    };
  }
}

export async function getAsyncSelectLoadOptionsNoPaginate(
  apiCallFunction: Function,
  { page, page_size = 25, ...searchParams }: { page: number; page_size?: number; [key: string]: any }
) {
  try {
    const response = await apiCallFunction({ page: page, page_size: page_size, ...searchParams });

    // const { current_page, last_page } = response?.meta_data?.pagination;

    return {
      options: response?.payload,
      hasMore: false,
      additional: {
        page: page + 1,
      },
    };
  } catch (err: any) {
    toaster.error({ description: err?.message });
    return {
      options: [],
      hasMore: false,
    };
  }
}

/**
 * Same as the getAsyncSelectLoadOptions above with an extra option of mutating the response payload
 *
 * @param apiCallFunction
 * @param param1
 * @param responseMutateFn
 * @returns
 */
export async function getAsyncSelectLoadOptionsMutate(
  apiCallFunction: Function,
  { page, page_size = 25, ...searchParams }: { page: number; page_size?: number; [key: string]: any },
  responseMutateFn: Function
) {
  try {
    const response = await apiCallFunction({ page: page, page_size: page_size, ...searchParams });

    const { current_page, last_page } = response?.meta_data?.pagination;

    return {
      options: response?.payload ? responseMutateFn(response?.payload) : response?.payload,
      hasMore: last_page > current_page,
      additional: {
        page: page + 1,
      },
    };
  } catch (err: any) {
    toaster.error({ description: err?.message });
    return {
      options: [],
      hasMore: false,
    };
  }
}
/**
 *
 * @param {Function} apiCallFunction
 * @param {Object} searchParams
 * @param {Function} apiCall
 */

type TModule =
  | 'whoIsWorking'
  | 'timesheet'
  | 'scheduling'
  | 'integrations'
  | 'timeOff'
  | 'reports'
  | 'billing'
  | 'companySettings';
/**
 *
 * @param {TModule} route
 * @returns {string}
 */
export function getOldAppUrl(module: TModule) {
  const userUniqueToken = tokenService.getUserUniqueToken();
  const userInfo = getUserInfo();
  const oldAppUrl = import.meta.env.VITE_OLD_APP_URL as string;
  let route = 'whosworking';

  switch (module) {
    case 'whoIsWorking':
      route = 'whosworking';
      break;

    case 'timesheet':
      route = 'timeEntries';
      break;

    case 'scheduling':
      route = 'scheduling/view';
      break;

    case 'integrations':
      route = 'integrations';
      break;

    case 'timeOff':
      route = userInfo?.roleId === 3 ? 'requestTimeOff' : 'addTimeOffCategory';
      break;

    case 'reports':
      route = 'getReports/byJob';
      break;

    case 'billing':
      route = 'billing';
      break;

    case 'companySettings':
      route = 'settings';
      break;

    default:
      route = 'whosworking';
  }

  return `${oldAppUrl || ''}home/${route}?token=${userUniqueToken || ''}`;
}

export function getCompanyInfo() {
  const user: any = useUserStore.getState().user;
  return user?.companyInfo || {};
}

export function getUserInfo() {
  const user: any = useUserStore.getState().user;
  return user?.userInfo || {};
}

export function getTimeRoundingInfo() {
  const user: any = useUserStore.getState().user;
  return user?.policies?.timeRounding || {};
}

export function getUserStore() {
  const userDetails: any = useUserStore.getState().user;
  return userDetails || {};
}

export function getIntegrationStore() {
  const integrationList: any = useIntegrationStore.getState().integrationList;
  return integrationList || {};
}

export function getFeatureList() {
  const featureList: any = useFeatureStore.getState().features;
  return featureList || {};
}

export function getAdpWfnTypeKey() {
  const key: string = useAdpWfnStore.getState().key;
  const type: string = useAdpWfnStore.getState().type;

  return { key, type };
}

/**
 *
 * @param {string | number} value
 * @returns {string}
 */
export function secondsToHms(secondsInput: string | number) {
  secondsInput = Number(secondsInput);
  if (secondsInput < 0) return '00:00:00';

  const hours = Math.floor(secondsInput / 3600)
    .toString()
    .padStart(2, '0');
  const minutes = Math.floor((secondsInput % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const seconds = Math.floor((secondsInput % 3600) % 60)
    .toString()
    .padStart(2, '0');

  return hours + ':' + minutes + ':' + seconds;
}

/**
 *
 * @param {string | number} value
 * @returns
 */
export function secondsToHm(secondsInput: string | number) {
  const isNegative = secondsInput < 0 ? true : false;
  secondsInput = Math.abs(Number(secondsInput));

  const hours = Math.floor(secondsInput / 3600)
    .toString()
    .padStart(2, '0');

  const minutes = Math.floor((secondsInput % 3600) / 60)
    .toString()
    .padStart(2, '0');
  return { hours: isNegative ? '-' + hours : hours, minutes };
}

/**
 * Converts a given number of seconds into minutes and seconds.
 * @param {string | number} secondsInput - The input representing seconds, either as a string or a number.
 * @returns {Object} An object containing the converted minutes and seconds.
 * @returns {string} Object.minutes - The converted minutes.
 * @returns {string} Object.seconds - The converted seconds.
 */
export function secondsToMS(secondsInput: string | number): { minutes: string; seconds: string } {
  secondsInput = Number(secondsInput);
  if (secondsInput < 0) return { minutes: '0', seconds: '0' };

  const minutes = Math.floor(secondsInput / 60)
    .toString()
    .padStart(2, '0');
  const seconds = Math.floor((secondsInput % 3600) % 60)
    .toString()
    .padStart(2, '0');

  return { minutes, seconds };
}

export function convertTimeInMinutes(time: string) {
  const [hour, minute] = time.split(':');
  return Number(hour) * 60 + Number(minute);
}

export function convertSecondsToMinutes(seconds: number) {
  return Math.round(Number(seconds) / 60);
}

export function convertSecondsToMinutesRoundDown(seconds: number) {
  return Math.floor(Number(seconds) / 60);
}

export function downloadFile(blob: any, fileName: string) {
  const a = document.createElement('a');
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
    toaster.success({ description: 'File downloaded successfully' });
  }, 0);
}

/**
 *
 * @param theBlob The file object
 * @param fileName File name
 * @returns {Blob}
 */
export function blobToFile(theBlob: any, fileName: string) {
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;

  return theBlob;
}

/**
 * @param extension file extension name
 * @returns {string} file types
 */
export function mimeType(extension: string) {
  switch (extension) {
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    case 'xls':
      return 'application/vnd.ms-excel';

    default:
      return 'text/csv';
  }
}

export function getHoursAndMinutes(date: Date | string) {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  return String(date.getHours()).padStart(2, '0') + ':' + String(date.getMinutes()).padStart(2, '0');
}

/**
 *
 * @param {number} distanceInMiles
 * @returns {number}
 */
export function getDistanceInCompanyFormat(distanceInMiles: number) {
  const companyInfo = getCompanyInfo();

  if (companyInfo?.milesInKm) {
    return distanceInMiles * MILES_IN_KILOMETERS;
  }
  return distanceInMiles;
}

/**
 *
 * @param {number} distance
 * @returns {number}
 */
export function postDistanceInMiles(dsitance: number) {
  const companyInfo = getCompanyInfo();

  if (companyInfo?.milesInKm) {
    return dsitance / MILES_IN_KILOMETERS;
  }
  return dsitance;
}

/**
 *
 * @returns {boolean}
 */
export function isDistanceInMiles() {
  const companyInfo = getCompanyInfo();

  if (companyInfo?.milesInKm) {
    return false;
  }
  return true;
}

/* The number of minutes returned by getTimezoneOffset() is positive if
 * the local time zone is behind UTC, and negative if the local time zone is ahead of UTC
 */
export function getTimezoneOffsetInHM() {
  return format(new Date(), 'xxx');
}

/**
 * Checks if the given string is a date string
 *
 * @param {string} givenString
 * @returns {boolean}
 */
export function isDateString(givenString: string) {
  return !isNaN(new Date(givenString) as any);
}

/**
 * Gets the label-value pairs from an object used in the timesheet edit logs
 *
 * @param {any} givenObj The object to extract label-value pairs
 * @returns {Array}
 */
export function getLabelValue(givenObj: any) {
  const result: ILabelValueOptions[] = [];
  if (givenObj.hasOwnProperty('break')) {
    Object.values(givenObj.break).map((value: any) => result.push(value));
  } else {
    Object.values(givenObj).map((value: any) => result.push(value));
  }

  return result;
}

/**
 * Converts given date to be relative to the base date
 *
 * @param {Date} givenDate The date to be converted relative to the baseDate
 * @param {Date} baseDate
 * @returns {string} The relative date string
 */
export function convertToRelativeTime(givenDate: Date, baseDate: Date) {
  return formatRelative(givenDate, baseDate);
}

/**
 * Adds an x number of months to a given date
 *
 * @param {Date} givenDate
 * @param {number} months
 * @returns {Date} The date with added number of months
 */
export function addMonthsToDate(givenDate: Date, months: number) {
  const d = givenDate.getDate();
  givenDate.setMonth(givenDate.getMonth() + +months);
  if (givenDate.getDate() != d) {
    givenDate.setDate(0);
  }

  return givenDate;
}

/**
 * Converts an array of options to a react-select friendly array
 *
 * @param {Array} options
 * @returns {Array<ILabelValueOptions>} react-select friendly array
 */
export function convertToReactSelectArr(options: any[]) {
  const updatedOptions: ILabelValueOptions[] = options.map((item: any) => {
    return {
      label: item,
      value: item,
    };
  });

  return updatedOptions;
}

/**
 * Updates given date with given hour and minute values
 *
 * @param date
 * @param hourMinValue Hour minutes value in the form of HH:mm
 * @returns Updated date
 */
export function updateHoursMinutesToExistingDate(date: string | Date, hourMinValue: string) {
  if (!hourMinValue) return date;

  const splitHourMinValue = hourMinValue.split(':');
  const newDate = new Date(date);

  newDate.setHours(parseInt(splitHourMinValue[0]), parseInt(splitHourMinValue[1]));

  return newDate;
}

/**
 * Adds minutes to a given date in milliseconds
 *
 * @param date
 * @param minutes
 * @returns Milliseconds of a given date with the added minutes
 */
export function addMinutesMilliseconds(date: Date, minutes: number) {
  return new Date(date.getTime() + minutes * 60000).getTime();
}

/**
 * Converts a millisecond time signature to minutes
 *
 * @param milliseconds
 * @returns Converted minutes
 */
export function convertMillisecondsToMinutes(milliseconds: number, round?: boolean) {
  let minutes = milliseconds / 60000;
  if (round) minutes = Math.round(minutes);
  return minutes;
}

/**
 * Converts count to 4 levels: 1000+, 2000+, 10000+, and 50000+
 * @param count
 */
export function formatCount(count: number): string {
  let formattedCount = count.toString();

  if (count > 1000 && count < 2000) {
    formattedCount = '1000+';
  } else if (count >= 2000 && count < 10000) {
    formattedCount = '2000+';
  } else if (count >= 10000 && count < 50000) {
    formattedCount = '10000+';
  } else if (count >= 50000) {
    formattedCount = '50000+';
  }

  return formattedCount;
}

/**
 * Formats phone numbers to (xxx)-xxx-xxxx format
 * @param num
 * @returns
 */
export function formatPhoneNum(num = '') {
  // Reduces to number only values
  const v = num.replace(/\s+/g, '').replace(/[^0-9]/gi, '');

  const parts = [];
  if (num.length > 3) {
    // Taking the first 6 numbers and formatting them
    parts.push(v.substring(0, 3));
    parts.push(v.substring(3, 6));

    const restOfNum = v.substring(6);

    // Append the rest of the numbers to account for overflow
    for (let i = 0; i < restOfNum.length; i += 4) {
      parts.push(restOfNum.substr(i, 4));
    }
  } else {
    parts.push(v.substring(0, 3));
  }

  return parts.join('-');
}

/**
 * Formats numerical representation of hh:mm to x hrs y mins
 *
 * @param time
 * @returns string
 */
export function formatHHMM(time = '00:00') {
  const timeArr = time.split(':');
  const hrArr: string = timeArr[0];
  const minArr: string = timeArr[1];
  let formattedTime = '';
  let formattedMin = '';

  for (let i = 0; i < hrArr.length; i++) {
    if (hrArr[i] !== '0') {
      formattedTime += hrArr.slice(i);
      break;
    }
  }

  if (formattedTime.length > 0) {
    formattedTime += ' hr';
  }

  for (let j = 0; j < minArr.length; j++) {
    if (minArr[j] !== '0') {
      formattedMin += minArr.slice(j);
      break;
    }
  }

  if (formattedMin.length > 0) {
    formattedMin += ' min';
  }

  return formattedTime + ' ' + formattedMin;
}

/**
 * Converts a keys array to shift data
 *
 * @param value
 * @returns
 */
export const convertToShiftData = (keys: string[], searchQuery: URLSearchParams) => {
  if (!searchQuery) return {};
  const shiftDetails: { [key: string]: any } = {};
  keys.forEach(key => {
    if (searchQuery.get(key)) {
      shiftDetails[key] = searchQuery.get(key);
    }
  });
  return shiftDetails;
};
