import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import Banner from 'components/Banner';
import { routePaths } from 'global/routePaths';
import tokenService from 'utils/token';

const PublicRoute: React.FC<TAppRoute> = ({ component: Component, exact, path, ...rest }) => {
  return (
    <Route
      {...rest}
      path={path}
      exact={exact}
      render={(props: any) => {
        if (!tokenService.getAccessToken()) {
          return (
            <>
              <Banner />
              <Component {...props} />{' '}
            </>
          );
        }

        return <Redirect to={routePaths.dashboard} />;
      }}
    />
  );
};

export default PublicRoute;
