import { Icon, Flex } from '@chakra-ui/react';
import React, { memo } from 'react';

import { LogoTextLight } from 'assets/icons';

import './PageLoader.scss';

const PageLoader: React.FC<{}> = () => {
  return (
    <Flex justifyContent="center" alignItems="center" h="50vh" width="100%" flexDirection="column">
      <Icon
        // as={LogoTextDark}
        as={LogoTextLight}
        height="90px"
        width="auto"
        // sx={{ '& rect': { fill: 'gray.100' }, '& path:last-child': { fill: 'primary.900' } }}
      />
      <div className="lds-facebook">
        <div></div>
      </div>
    </Flex>
  );
};

export default memo(PageLoader);
