import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// import ErrorBoundary from 'components/ErrorBoundary';
import ExternalRedirectLink from 'components/ExternalRedirectLink';
import { routePaths } from 'global/routePaths';
import { allowedRoute } from 'utils/allowedRoutes';
import tokenService from 'utils/token';

/**
 * If user is authenticated then users can't access routes related to authentication
 * If user is not authenticated then users can't access routes other than authentication
 * Don't use variable for storing tokenService accessToken because it returns old value even we update the token value
 */
const PrivateRoute: React.FC<TAppRoute> = ({ component: Component, exact, path, access, externalLink, ...rest }) => {
  return (
    <Route
      {...rest}
      path={path}
      exact={exact}
      render={(props: any) => {
        if (!tokenService.getAccessToken() || tokenService.getAccessToken() === 'undefined') {
          return <Redirect to={routePaths.auth.login} />;
        }

        if (externalLink) {
          return <ExternalRedirectLink redirectLink={externalLink} />;
        }

        if (allowedRoute(access)) {
          return <Component {...props} />;
        } else {
          return <Redirect to={routePaths.dashboard} />;
        }
      }}
    />
  );
};

export default PrivateRoute;
