import { ChakraProvider } from '@chakra-ui/react';
import { useContext, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import 'assets/scss/app.scss';
import ErrorBoundary from 'components/ErrorBoundary';
import ErrorMapper from 'components/ErrorMapper';
import { TrackerContext } from 'components/TrackerProvider/Provider';
import theme from 'theme';
import { WithSentryProfiler } from 'utils/sentry';
import toaster from 'utils/toaster';

import AppRouter from './AppRouter';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      onError: (errorData: any) => {
        console.log(errorData);
        let errorMessage: any = '';
        if (errorData?.errors) {
          errorMessage = <ErrorMapper errorObj={errorData.errors} fallbackText={errorData.message} />;
          toaster.error({ description: errorMessage });
        } else {
          errorMessage = errorData?.message || errorData?.error?.message || 'Unable to fetch data';
          toaster.error({ description: errorMessage });
        }
      },
    },
    mutations: {
      retry: 0,
      onError: (errorData: any) => {
        console.log(errorData);
        let errorMessage: any = '';
        if (errorData?.errors) {
          errorMessage = <ErrorMapper errorObj={errorData.errors} fallbackText={errorData.message} />;
          toaster.error({ description: errorMessage });
        } else {
          errorMessage = errorData?.message || errorData?.error?.message || 'Unable to post data';
          toaster.error({ description: errorMessage });
        }
      },
      onSuccess: (data: any) => {
        if (data?.message) {
          toaster.success({ description: data?.message });
        } else {
          toaster.success({ description: 'Task done successfully!' });
        }
      },
    },
  },
});

const App = () => {
  const { startTracking, initTracker } = useContext<any>(TrackerContext);

  useEffect(() => {
    initTracker();
    startTracking();

    function cleanRoutePath(path: string) {
      // Regex to remove any strings before hash value
      return path.replace(/\/[^#]*#/, '/#');
    }

    function cleanAndReplaceUrl() {
      const path = window.location.href;
      const cleanedPath = cleanRoutePath(path);

      if (path !== cleanedPath) {
        history.replaceState(null, '', cleanedPath);
      }
    }

    cleanAndReplaceUrl();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <AppRouter />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ErrorBoundary>
    </ChakraProvider>
  );
};

export default WithSentryProfiler(App);
