// @ts-nocheck
import Tracker from '@openreplay/tracker';
import { createContext, useReducer } from 'react';

import { getUserInfo } from 'utils/helpers';

export const TrackerContext = createContext();

const userInfo: any = getUserInfo();

function newTracker(config) {
  const trackerConfig = {
    projectKey: import.meta.env.VITE_OPENREPLAY_PROJECT_ID,
    ingestPoint: import.meta.env.VITE_OPENREPLAY_URL,
    defaultInputMode: 0,
  };

  const tracker = new Tracker(trackerConfig);

  return tracker;
}

function reducer(state, action) {
  switch (action.type) {
    case 'init': {
      if (!state.tracker) {
        console.log('Instantiaing the tracker for the first time...');
        return { ...state, tracker: newTracker(state.config) };
      }
      return state;
    }
    case 'start': {
      console.log(state.tracker);
      console.log('Starting tracker...');
      state.tracker.start();
      return state;
    }
    case 'initUser': {
      console.log('Adding user ID...');
      state.tracker.setUserID(action.payload || 'WARNING - NO USER ID ENTERED');
      console.log(state.tracker);
      return state;
    }
    case 'setMetadata': {
      console.log('Setting Metadata...');
      state.tracker.setMetadata(action.payload.tag, action.payload.value);
      return state;
    }
  }
}

interface ITrackerProvider {
  children: React.ReactNode;
  config: any;
}

// eslint-disable-next-line react/prop-types
const TrackerProvider: React.FC<ITrackerProvider> = ({ children, config = {} }) => {
  const [state, dispatch] = useReducer(reducer, { tracker: null, config });
  const value = {
    startTracking: () => dispatch({ type: 'start' }),
    initTracker: () => dispatch({ type: 'init' }),
    initUser: (userId: any) => dispatch({ type: 'initUser', payload: userId }),
    setMetadata: (tag: any, value: any) => dispatch({ type: 'setMetadata', payload: { tag: tag, value: value } }),
  };

  return <TrackerContext.Provider value={value}>{children}</TrackerContext.Provider>;
};

export default TrackerProvider;
