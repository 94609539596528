import { lazy } from 'react';

import * as localStorage from './storage';

const PAGE_REFRESHED = 'page-has-been-force-refreshed';

const lazyWithRetry = (componentImport: any) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = localStorage.get(PAGE_REFRESHED) || 'false';
    try {
      const component = await componentImport();

      localStorage.set(PAGE_REFRESHED, 'false');

      return component;
    } catch (error) {
      if (pageHasAlreadyBeenForceRefreshed === 'false') {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        localStorage.set(PAGE_REFRESHED, 'true');
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });

export { lazyWithRetry };
