import React, { Suspense } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import PageLoader from 'components/PageLoader';
import { PrivateRoute, PublicRoute } from 'components/Route';
import ScrollToTop from 'components/ScrollToTop';
import ChangePassword from 'containers/Auth/ChangePassword';
import ForgotPassword from 'containers/Auth/ForgotPassword';
import Login from 'containers/Auth/Login';
import ResetPassword from 'containers/Auth/ResetPassword';
import ResetPasswordSuccess from 'containers/Auth/ResetPasswordSuccess';
import SignUp from 'containers/Auth/SignUp';
import Home from 'containers/Home';
import Page404 from 'containers/Page404';
import Shift from 'containers/Shift';
import ThankYou from 'containers/ThankYou';
import { routePaths } from 'global/routePaths';

const AppRouter: React.FC<{}> = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path={routePaths.shift} component={Shift} />
          <Route exact path={routePaths.notFound} component={Page404} />
          <PublicRoute exact path={routePaths.auth.login} component={Login} />
          <PublicRoute exact path={routePaths.auth.forgotPassword} component={ForgotPassword} />
          <PublicRoute exact path={routePaths.auth.changePassword} component={ChangePassword} />
          <PublicRoute exact path={routePaths.auth.resetPassword} component={ResetPassword} />
          <PublicRoute exact path={routePaths.auth.resetPasswordSuccess} component={ResetPasswordSuccess} />
          <PublicRoute exact path={routePaths.auth.signUp} component={SignUp} />
          <PrivateRoute exact path={routePaths.thankYou} component={ThankYou} />
          <PrivateRoute path={routePaths.home} component={Home} />
          <Route path="*">
            <Page404 />
          </Route>
        </Switch>
      </Router>
    </Suspense>
  );
};

export default AppRouter;
