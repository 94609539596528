export const roles = {
  ADMIN: 1,
  MANAGER: 2,
  EMPLOYEE: 3,
};

export const permisssions = {
  MANAGEJOBS: 'manageJobs',
  MANAGEMESSAGES: 'manageMessages',
  ACCESSREPORTS: 'accessReports',
  MANAGESCHEDULES: 'manageSchedules',
  MANAGETIMESHEETS: 'manageTimesheets',
  WHOISWORKING: 'whoIsWorking',
};
