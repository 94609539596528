import { Box, Heading, Text } from '@chakra-ui/react';
import React, { ErrorInfo } from 'react';
import { Translation } from 'react-i18next';

import Button from 'components/Button';
import { catchErrorsWithScope } from 'utils/sentry';

interface IState {
  /** Flag to indicate if error captured or not */
  hasError: boolean;
}

class ErrorBoundary extends React.Component<any, IState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      hasError: false,
    };

    this.refreshPage = this.refreshPage.bind(this);
  }

  static getDerivedStateFromError(): IState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    if (import.meta.env.DEV) {
      console.log({ error: error, errorInfo: info });
    }
    catchErrorsWithScope(error, info);
  }

  refreshPage() {
    window.location.reload();
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="60vh" textAlign="center" role="alert">
          <Translation ns="components">
            {t => (
              <div>
                <Heading as="h2">{t('somethingWentWrong')}</Heading>
                <Text my={2}>{t('oopsLooksLikeThereWasAnErrorWeAreOnIt')}</Text>
                <Button onClick={this.refreshPage}>{t('refreshPage')}</Button>
              </div>
            )}
          </Translation>
        </Box>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
