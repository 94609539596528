import { Flex, Heading, Spinner, Text } from '@chakra-ui/react';

const ExternalRedirectLink = ({ redirectLink }: { redirectLink: string }) => {
  window.location.href = redirectLink;

  return (
    <Flex p="7" alignItems="center" justifyContent="center" flexDirection="column" minHeight="340px">
      <Spinner size="lg" emptyColor="gray.200" color="primary.500" thickness="6px" />
      <Heading as="h4" size="lg" mb="4">
        Redirecting...
      </Heading>
      <Text>Please wait a while. You are being redirected.</Text>
    </Flex>
  );
};

export default ExternalRedirectLink;
